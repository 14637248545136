html {
  background: url("./assets/dark_mosaic.png");
  color: white;
}

body {
  font-family: sans-serif;
  margin: 0;
}

h2 {
  margin: 0.25rem;
  font-size: 2.5rem;
}

.intro {
  display: flex;
  justify-content: center;
  padding-bottom:4rem;
}

.intro-wrapper {
  padding: 1rem 0.75rem;
}

.slide {
  transform: translateX(-100%);
  opacity: 0;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  animation-fill-mode: forwards;
}

.intro1 {
  animation-delay: 0s;
}

.intro2 {
  animation-delay: 0.35s;
}

.intro3 {
  animation-delay: 0.7s;
}

.fade {
  opacity: 0;
  animation: 1.3s ease-out 0s 1 fadeIn;
  animation-fill-mode: forwards;
}
.about {
  animation-delay: 1.4s;
}

.card {
  padding: 0.5rem;
  margin: 0.7rem;
  display: flex;
  background: #53535359;
}

.pic {
  padding: 0.7rem;
}
.pic > img {
  height: 7em;
}

.footer {
  position: fixed;
  background: #111111;
  width: 100vw;
  bottom: 0;
  padding: 0.5rem 0.2rem;

  opacity: 0;
  animation: 1.3s ease-out 0s 1 fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 1.9s;
  display: flex;
  justify-content: center;
  
}
.footer-wrangler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  max-width: 800px;
}
.doc-img{
  width:30px;
}
.resume{
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 420px;
  width:100%;

}
.resume-wrapper{
  animation-delay: 1.85s;
  padding: .7rem;
  margin: 1rem 1rem;
  display:flex;
  justify-content: center;
  background: #53535359;
}
.email {
  color: #ffffff9c;
  margin-left: 1rem;
}

.icons {
  margin: 0 1rem;
  width: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons > a > img {
  height: 20px;
}

.slider {
  animation-delay: 1.7s;
}

.slider-wrapper {
  padding: 0.5rem;
  flex-direction: column;
  margin: 0.7rem;
  display: flex;
  flex-wrap: wrap;
  background: #53535359;
}
.slider-card {
  margin: 2px 4px;
}
.slider-card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.logo > img {
  height: 25px;
}
.slider-card-wrapper > div {
  margin-left: 1rem;
}

.container {
  max-width: 720px;
  width:100%;
}

.logo {
  width: 39px;
}
.git-card {
  animation-delay: 1.85s;
  padding: .7rem;
  margin: 1rem 1rem;

  background: #53535359;
}
.git-img {
  height: 2.8rem;
}
.git-card-text-head {
  padding:0 1.5rem;
  text-align: center;
}
.git-card-head {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
}
.git-card-body {
  padding: 0.7rem;
}
.git-card-body > p {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.git-card-body > a {
  color: #babeff;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 640px) {
  .about-text,
  .slider-card-wrapper {
    font-size: 1.3rem;
  }
  .logo > img {
    height: 40px;
  }
  .logo {
    width: 65px;
  }
}
